import React from 'react';
import {Link, NavLink} from 'react-router-dom';


class Yogamarketingtips extends React.Component {
    render(){
        return(
            <>
                <section className="section-white margin-top-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="mb-4">8 Online Marketing Tips for Yoga classNamees & Studios to Build Yoga Brand</h3>
                            </div>

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="mb-4">1. Participate in community events</h4>
                                        <p>Offer mini yoga classNamees at your local community center, park, or corporate office where people can see and experience your teaching and techniques. Or you can collaborate with other wellness partners and join them to reach more students.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img className="mb-2" src={require('../images/yoga/m1.jpg').default} alt="yoga marketing"/>
                                    </div>

                                    <hr/>

                                    <div className="col-md-6">
                                        <img className="mb-2" src={require('../images/yoga/m2.jpg').default} alt="yoga marketing"/>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="mb-4">2. Use social media</h4>
                                        <p>Introduce your yoga practice on social media with some attractive photos, videos. You can offer some free sessions and grab clients with more followers. Due to the current scenario people rely on convenience and flexibility come with online workouts.</p>
                                    </div>

                                    <hr/>

                                    <div className="col-md-6">
                                        <h4 className="mb-4">3. Offer a challenge</h4>
                                        <p>Online or offline challenges with exciting rewards could grab more clients to your studios. Also, make sure that you reward those who win and participate in challenges, and post on social media.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img className="mb-2" src={require('../images/yoga/m3.jpg').default} alt="yoga marketing"/>
                                    </div>

                                    <hr/>

                                    <div className="col-md-6">
                                        <img className="mb-2" src={require('../images/yoga/m4.jpg').default} alt="yoga marketing"/>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="mb-4">4. Small things makes bigger impact</h4>
                                        <p>For your current students go above and beyond to offer them a post-className cup of tea or coffee or chocolates. Also, follow up with those students who have a special day or event in their life.</p>
                                    </div>

                                    <hr/>

                                    <div className="col-md-6">
                                        <h4 className="mb-4">5. Use email marketing strategy</h4>
                                        <p>With continued improvement and promotion to online and social media use email channels to get feedback and upcoming events and views about your classNamees and offerings.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img className="mb-2" src={require('../images/yoga/m5.jpg').default} alt="yoga marketing"/>
                                    </div>

                                    <hr/>

                                    <div className="col-md-6">
                                        <img className="mb-2" src={require('../images/yoga/m6.jpg').default} alt="yoga marketing"/>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="mb-4">6. Build a user friendly website</h4>
                                        <p>In this digital era, make sure you have a place where new students can directly connect with you. Put some testimonials and social media links with location details, booking system going to showcase your business.</p>
                                    </div>

                                    <hr/>

                                    <div className="col-md-6">
                                        <h4 className="mb-4">7. "Content Marketing" is a key</h4>
                                        <p>You need great content for your social media handles and website. A single type of content won’t work every time so it makes sense to write different blog posts, Images, email newsletters, podcasts, quizzes, and polls.</p>
                                    </div>
                                    <div className="col-md-6">
                                        <img className="mb-2" src={require('../images/yoga/m7.jpg').default} alt="yoga marketing"/>
                                    </div>

                                    <hr/>

                                    <div className="col-md-6">
                                        <img className="mb-2" src={require('../images/yoga/m8.jpg').default} alt="yoga marketing"/>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="mb-4">8. Understand search marketing</h4>
                                        <p>Appearing in the top list of search engines like Google use best “keywords”. Which brings free and organic traffic to your business.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}

export default Yogamarketingtips