import React from 'react';

class Contact extends React.Component {
  render(){
    return (
      <main>
        <div className="container pt-3">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <iframe class="w-100" src="https://docs.google.com/forms/d/e/1FAIpQLScqMdl1rokHxhPuPXWKinJgkaSwRJp_Pwq3CAp_sTrgYu0dtw/viewform?embedded=true" width="640" height="915" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>

            <div className="col-lg-4 col-md-4 col-12">
              <h5 class="mb-3">Contact Details</h5>
              <div className="mb-2">
                <button className="contactBtn btn btn-danger mr-2"><i className="fa fa-whatsapp"></i></button>
                <a target="_blank" href="//api.whatsapp.com/send?phone=+16503084410">+1 6503084410</a>
              </div>
              <div>
                <button className="contactBtn btn btn-danger mr-2"><i className="fa fa-envelope"></i></button>
                <a target="_blank" href="mailto:contact@holo.in.net">contact@holo.in.net</a>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Contact;
