import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

class Tax extends React.Component {
  render(){
    return (
      <main>
        <section className="breadcrumb_bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul className="va_breadcrumb">
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/virtualassistant">Virtual Assistant</NavLink>
                  </li>
                  <li>Tax & Accounting</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <section>
            <div className="row">
              <div className="col-md-9 col-12">
                <div className="service_name">
                  <h1>Tax & Accounting</h1>
                  <h2>Best Virtual service provider </h2>
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="call_btn mt-3">
                  <NavLink to="/contact">Request Call Back</NavLink>
                </div>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-md-12">
                <div class="child_services">
                  <p>Included Services</p>
                </div>
                <div class="box pl-10">
                  <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Bookkeeping/Write-Up</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Generate meaningful, well-organized financial records
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Financial Statements</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Uploading files to YouTube, DailyMotion and Vimeo
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Audits, Reviews and Compilations</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            An intensive examination with the highest level of
                            assurance
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Accounting Services</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            General ledger &amp; financial statement
                            preparation,Accounting system setup,Business tax
                            return
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Tax Services</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Tax planning &amp; return preparation for all kind of
                            tax,Taxing authority representation
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    );
  }
};

export default Tax;
