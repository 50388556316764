import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.css';
import ScrollToTop from './pages/ScrollToTop';
import { BrowserRouter } from 'react-router-dom';


ReactDOM.render(
  <>
    <BrowserRouter basename='/'>
      <ScrollToTop/>
      <App/>
    </BrowserRouter>
  </>,
  document.getElementById('root')
);
