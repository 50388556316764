import React from 'react';
import {Link, NavLink} from 'react-router-dom';


class Yogawebsite extends React.Component {
    render(){
        return(
            <>
                <section class="section-white margin-top-100">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <h3 class="mb-4">Stunning Web Designs</h3>
                                <div class="about-text">
                                    <p class="mb-2">Access an experienced team of web designers/developers to build your website</p>
                                    <p class="mb-2">A website is the virtual door way to your business and provides your customers a way to engage and interact with you and your brand.</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <img src={require('../images/yoga2.jpg').default} class="width-100 margin-right-15 box-shadow" alt="pic"/>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="">
                    <div class="w-100 d-flex">
                        <div class="w-50 colorDark">
                            <div class="innerDiv">
                                <h3 class="mb-4">The Problem</h3>
                                <div class="about-text">
                                    <p class="mb-2">In order to promote yourself anywhere online, you always need somewhere to direct potential new students. If you don't already have a website, then where do you send them?</p>
                                    <p class="mb-2">If you're only on social media, you may be missing out on key opportunities from your students searches for you online!</p>
                                </div>
                            </div>
                        </div>

                        <div class="w-50 colorLight">
                            <div class="innerDiv">
                                <h3>The Goal</h3>
                                <ul>
                                    <li>Increase Brand Awareness</li>
                                    <li>Increase your market reach</li>
                                    <li>Build a pleasant experience for your students</li>
                                    <li>Present a professional front</li>
                                    <li>Provide a way for students to engage 24/7</li>
                                    <li>Provide a space for your students to sign-up</li>
                                    <li>Sell products and services</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Yogawebsite