import React from 'react';
import {Link, NavLink} from 'react-router-dom';


class Yogaseo extends React.Component {
    render(){
        return(
            <>
                <section className="section-white margin-top-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="mb-4">SEO enhancement</h3>
                                <div className="about-text">
                                    <p className="mb-2">One of the best advantages of having organic search optimization is "you are marketing your product on search engines for free." SEO provides localized traffic to your business and also a good SEO site is responsive to mobile users as well. Potential clients can easily find your site because of SEO which in turn increases the conversion rate to your studio. It provides 24x7 promotion to your business.</p>
                                    <p className="mb-2">It optimizes your website's technical configuration and acclaims your web link so it is easy to find users' search queries. It helps people find what they want on the web. For Business (Yoga Guru's) Teachers, it's an opportunity to get direct relevant traffic to their website.</p>
                                    <p className="mb-2">Moreover, Google processes billions of searches for their users which needs huge investments for organic and secure rankings every click on the website is completely free. high-quality content and keywords will help you with SEO. It boosts the online visibility of your business over a while with continuity. There are high chances that users find the relevant information and content on your business leads to more business conversions and getting you more leads with increasing sales. Keep your content simple yet stay on topic. Readers get the right solution to their problems. Also include rich content which refers to some Audio, Video, and some testimonials.</p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <img src={require('../images/yoga2.jpg').default} className="width-100 margin-right-15 box-shadow" alt="pic" />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Yogaseo