import React from 'react';
import {Link, NavLink} from 'react-router-dom';


class Yogaemail extends React.Component {
    render(){
        return(
            <>
                <section class="section-white margin-top-100">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <h3 class="mb-4">Increase Your Productivity </h3>
                                <div class="about-text">
                                    <p class="mb-2">Use Google Workspace for business email, video calls, cloud storage, and sharing files. If you work with a team, Google workspace will allow you to collaborate with them effectively and efficiently.</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <img src={require('../images/yoga2.jpg').default} class="width-100 margin-right-15 box-shadow" alt="pic"/>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="">
                    <div class="w-100 d-flex">
                        <div class="w-50 colorDark">
                            <div class="innerDiv">
                                <h3 class="mb-4">The Problem</h3>
                                <div class="about-text">
                                    <p class="mb-2">You have lots of different tools covering your essential needs as a business. For example, you may be using DropBox for cloud storage and then WeTransfer to share files. Why not make it easy fo yourself (and your team) and gain all of these tool in google Workspace? </p>
                                </div>
                            </div>
                        </div>

                        <div class="w-50 colorLight">
                            <div class="innerDiv">
                                <h3>The Goal</h3>
                                <ul>
                                    <li>Effectively store files in the cloud</li>
                                    <li>Have a professional business email address</li>
                                    <li>Communicate with your team effectively</li>
                                    <li>Share files with students and your team</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Yogaemail