import React from 'react';

class Blogdetail2 extends React.Component {
  render(){
    return (
      <main>
         <div className="container blogs pt-3">
          <div className="row">
            <div className="col-lg-9 col-md-9 col-12">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div class="card mb-4">
                      <img className="w-100" src={require('../images/blogs/blog2.jpg').default} alt="blog"/>
                      <div class="card-body">
                          <h5 class="card-title">AI- Artificial Intelligence - Future of IT - Is it worth it?</h5>
                          <img className="w-100" src={require('../images/blogs/blog2-1.jpg').default} alt="" />
                          <p className="mb-3"><strong>Wouldn’t it be nice if all of our work is being done with just a gesture?</strong></p>
                          <p className="mb-3"><strong>What if humans have to just sit & relax in a chair while the machine does everything?</strong></p>
                          <p className="mb-3"><strong>How wonderful it will be when robots have their brain which can learn from experiences?</strong></p>
                          <p><strong>The answer is YES & NO.</strong></p>
                          <p className="mb-3">Yes, we are talking about AI (Artificial Intelligence). Ruler of future tech & one-stop solution for all of our human brain limitations.</p>
                          <img className="w-100" src={require('../images/blogs/blog2-2.jpg').default} alt="" />
                          <p className="mb-3">Tech companies in this world are spending billions of dollars nowadays after AI research. Today, we use content marketing, SEO, Social Media Marketing, youtube marketing & live website design with Holographic technology. But, AI is significantly changing everything.</p>
                          <img className="w-100 mb-3" src={require('../images/blogs/blog2-3.png').default} alt="" />
                          <p className="mb-3">Days are not that far in the future when AI will be able to learn from its past experiences similar to humans. The word itself is self - explanatory - Intelligence created by we humans. Just give a command and the work gets done. Next time, before you even give the command, AI will automatically come up with suggestions for you about getting the work done. Of course, with your permission.</p>
                          <div className="row">
                              <div className="col-6">
                                  <p>But, wait…!!! What if it doesn't ask you for your permission? Or what if it starts taking decisions on its own…!</p>
                                  <p>There is a possibility. Elon Musk, founder of Tesla & SpaceX is always concerned with this aspect. He has publicly confessed that AI is an evil demon that  we are trying to summon into our world.</p>
                              </div>
                              <div className="col-6">
                                  <img className="w-100 mb-3" src={require('../images/blogs/blog2-4.jpg').default} alt="" />
                              </div>
                          </div>
                          <img className="w-100 mb-3" src={require('../images/blogs/blog2-5.png').default} alt="" />
                          <p><strong>Positive Points are there:</strong></p>
                          <p>Medical industry can benefit a lot.Manufacturing units can be automatic and product price can decrease worldwide.Human safety factor can be improved due to low risk factor.Emergency services can be improved with advanced weather prediction systems.</p>
                          <p className="mb-3">And so on… endless opportunities.</p>

                          <div className="row">
                              <div className="col-6">
                                  <img className="w-100 mb-3" src={require('../images/blogs/blog2-6.jpg').default} alt="" />
                              </div>
                              <div className="col-6">
                                  <p>But, Only one big IF,</p>
                                  <p>What if it starts controlling Humans? Mankind? Internet?</p>
                                  <p>Man created computers but, we are letting computers develop its brain.</p>
                                  <p>AI is the study of ideas that enable computers to be intelligent.</p>
                              </div>
                          </div>

                          <div className="row">
                              <div className="col-6">
                                  <p>It is global issue of debate whether to continue this research of AI or government should intervene putting a full stop to these potential threats.</p>
                                  <p>What is your thought process?</p>
                              </div>
                              <div className="col-6">
                                  <img className="w-100 mb-3" src={require('../images/blogs/blog2-7.png').default} alt="" />
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12">
                  <div>
                  <iframe className="w-100" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fholoonline%2F&amp;tabs=timeline&amp;width=360&amp;height=200&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" height="300" scrolling="no" frameborder="0" allowtransparency="true"></iframe>
                </div>
                <h5>Recent Post</h5>
                <ul>
                  <li>
                    <a href="#">Holo - SEO</a>
                  </li>
                  <li>
                    <a href="#">AI- Artificial Intelligence </a>
                  </li>
                  <li>
                    <a href="#">Good design doesn’t cost</a>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Blogdetail2;
