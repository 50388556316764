import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

class Editing extends React.Component {
  render(){
    return (
      <main>
        <section className="breadcrumb_bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul className="va_breadcrumb">
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/virtualassistant">Virtual Assistant</NavLink>
                  </li>
                  <li>Audio & Video Editing</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <section>
            <div className="row">
              <div className="col-md-9 col-12">
                <div className="service_name">
                  <h1>Audio & Video Editing</h1>
                  <h2>Best Virtual service provider </h2>
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="call_btn mt-3">
                  <NavLink to="/contact">Request Call Back</NavLink>
                </div>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-md-12">
                <div class="child_services">
                  <p>Included Services</p>
                </div>
                <div class="box pl-10">
                  <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Basic Video Editing</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Splicing intros and outros and inputting graphics and
                            music
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Uploading Video</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Uploading files to YouTube, DailyMotion and Vimeo
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Editing Audio</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Editing audio files by removing background noise and
                            improving volume levels
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Footage Editing</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Trimming footage segments and producing rough and
                            final cuts
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Podcasts For Webpage</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Recording, editing and setting up podcasts and
                            inserting them onto webpage
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Image Editing </h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Creating &amp; editing rudimentary graphic design
                            tasks on Photoshop &amp; other image editing software
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    );
  }
};

export default Editing;
