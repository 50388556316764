import React from 'react';
import {Link, NavLink} from 'react-router-dom';


class Yogamarketing extends React.Component {
    render(){
        return(
            <>
                <section className="home-section" id="home">
                    <div className="home-section-overlay"></div>
                    <div className="mainTitle">
                        <h1>Yoga Marketing</h1>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-12">

                            </div>
                            <div className="col-lg-9 col-md-12 text-center title">
                                <h2 className="heroText">You Love Yoga Teaching <br />We Love Yoga Marketing!</h2>

                                <div className="yogaMainSec">
                                    <NavLink className="button mt-0 mr-4" target="_blank" to="https://calendly.com/yogamarketingexpert/30min">Do it for me</NavLink>
                                    <NavLink className="buttonOutline mt-0" to="yogamarketingtips">Give me free tools</NavLink>
                                </div>
                                <div className="text-center gotoService">
                                    <NavLink to="#services"><ion-icon name="arrow-down-circle-outline"></ion-icon></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-white pt-5" id="services">
                    <div className="container-fluid">
                        <div className="text-center mb-4">
                            <h4 className="mb-1 titleText">We help you with...</h4>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-12 text-center">
                                <NavLink to="yogalogo">
                                    <div className="box-column">
                                        <div className="box-top twitter"></div>
                                        <div className="box-bottom">
                                            <div className="box-title twitter-title">
                                                Logo / Flyer Design
                                            </div>
                                            <div className="box-text">
                                                <p>The biggest part of your brand identity is your logo. It's what you want your students to recognise right away.</p>
                                                <p className="moreBtn"><span>Learn more</span> <ion-icon name="arrow-forward-circle-outline"></ion-icon></p>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12 text-center">
                                <NavLink to="yogawebsite">
                                    <div className="box-column">
                                        <div className="box-top instagram"></div>
                                        <div className="box-bottom">
                                            <div className="box-title instagram-title">
                                                Website Design
                                            </div>
                                            <div className="box-text">
                                                <p>The virtual door to your business! Allow your customers to engage and interact with you online by setting up a website.</p>
                                                <p className="moreBtn"><span>Learn more</span> <ion-icon name="arrow-forward-circle-outline"></ion-icon></p>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12 text-center">
                                <NavLink to="yogaemail">
                                    <div className="box-column">
                                        <div className="box-top automation"></div>
                                        <div className="box-bottom">
                                            <div className="box-title facebook-title">
                                                Funnels & automation piplines
                                            </div>
                                            <div className="box-text">
                                                <p>Present a professional image to students. This pack will allow you to work more efficiently as a team.</p>
                                                <p className="moreBtn"><span>Learn more</span> <ion-icon name="arrow-forward-circle-outline"></ion-icon></p>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12 text-center">
                                <NavLink to="yogaemail">
                                    <div className="box-column">
                                        <div className="box-top facebook"></div>
                                        <div className="box-bottom">
                                            <div className="box-title facebook-title">
                                                SEO & Social Media
                                            </div>
                                            <div className="box-text">
                                                <p>Present a professional image to students. This pack will allow you to work more efficiently as a team.</p>
                                                <p className="moreBtn"><span>Learn more</span> <ion-icon name="arrow-forward-circle-outline"></ion-icon></p>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-grey">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <img src={require('../images/yoga2.jpg').default} className="width-100 margin-right-15 box-shadow" alt="pic"/>
                            </div>
                            <div className="col-md-6">
                                <h3>Our Offerings</h3>
                                <div className="about-text">
                                    <ul>
                                        <li>
                                            <p>Beautiful yoga websites (Unlimited changes)</p>
                                        </li>
                                        <li>
                                            <p>Unlimited text changes or photo upgrades (mobile friendly)</p>
                                        </li>
                                        <li>
                                            <p>Unlimited blog/offer posting for your promotional activities.</p>
                                        </li>
                                        <li>
                                            <p>Yoga SEO + google reviews + digital marketing + Flyers /brochures.</p>
                                        </li>
                                    </ul>
                                    <NavLink className="button" to="https://calendly.com/yogamarketingexpert/30min" target="_blank">Do it for my Yoga Studio</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-white pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h3>Yoga Experts with yoga Flyer design + printing + shipping</h3>
                                <div className="about-text mb-5">
                                    <ul>
                                        <li>
                                            <p>Unlimited design changes</p>
                                        </li>
                                        <li>
                                            <p>3X faster shipping</p>
                                        </li>
                                        <li>
                                            <p>Designs dedicated to yoga students/learners.</p>
                                        </li>
                                        <li>
                                            <p>Design with Multiple text languages</p>
                                        </li>
                                    </ul>
                                    <NavLink className="button" to="https://calendly.com/yogamarketingexpert/30min" target="_blank">Do it for my Yoga Studio</NavLink>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <img src={require('../images/yoga4.jpg').default} className="width-100 margin-right-15 box-shadow" alt="pic"/>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mx-auto text-center">
                            <h4 className="mb-4 titleText"><span>Yoga Studio</span> Marketing</h4>
                                <p className="mb-2"><strong>We’re here to help take your yoga studio marketing to the next level!</strong></p>
                                <a href="https://calendly.com/yogamarketingexpert/30min" target="_blank" class="button mt-5">Inquire Now</a>
                                {/* <!-- Calendly inline widget begin --> */}
                                <div class="calendly-inline-widget" data-url="https://calendly.com/yogamarketingexpert/30min?primary_color=3f51b5"></div>

                                {/* <!-- Calendly inline widget end --> */}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-grey">
                    <div className="container">
                        <div className="text-center mb-4">
                            <h4 className="titleText">Minimum budget...Maximize ROI !</h4>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <NavLink to="yogaseo">
                                    <div className="box-column">
                                        <div className="box-top">
                                            <img src={require('../images/seo1.jpg').default} alt="seo"/>
                                        </div>
                                        <div className="box-bottom">
                                            <div className="box-title">
                                                SEO Enhancement
                                            </div>
                                            <div className="box-text">
                                                <p>How often do you go past the 1st page of Google? You need SEO enhancement to get to that number one spot!</p>
                                                <p className="moreBtn"><span>Learn more</span> <ion-icon name="arrow-forward-circle-outline"></ion-icon></p>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <NavLink to="yogamarketingtips">
                                    <div className="box-column">
                                        <div className="box-top">
                                            <img src={require('../images/yoga3.jpg').default} alt="yoga"/>
                                        </div>
                                        <div className="box-bottom">
                                            <div className="box-title">
                                                8 Online Marketing Tips for Yoga classNamees & Studios to Build Yoga Brand
                                            </div>
                                            <div className="box-text">
                                                <p>The biggest part of your brand identity is your logo. It's what you want your students to recognise right away.</p>
                                                <p className="moreBtn"><span>Learn more</span> <ion-icon name="arrow-forward-circle-outline"></ion-icon></p>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Yogamarketing
