import React from 'react';

class Blogdetail3 extends React.Component {
  render(){
    return (
      <main>
         <div className="container blogs pt-3">
          <div className="row">
            <div className="col-lg-9 col-md-9 col-12">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div class="card mb-4">
                      <img className="w-100" src={require('../images/blogs/blog3.png').default} alt="blog"/>
                      <div class="card-body">
                          <h5 class="card-title">Good design doesn’t cost, But it pays off</h5>
                          <p className="mb-3"><strong>Is your website updated to meet the latest changes in terms of search?</strong></p>
                          <p className="mb-3"><strong>How about having a responsive web design?</strong></p>
                          <p className="mb-3"><strong>The world has gone mobile, how about you?</strong></p>

                          <img className="w-100" src={require('../images/blogs/blog3-3.jpg').default} alt="" />
                          <p className="mb-3">Gone are the days when mere advertisements on television worked like magic in making people aware of the new products available in the market.Presentation makes your product stand out from the competition.</p>
                          <p className="mb-3">Web design is a process of creating a web page's appearance that determines the layout, colors, text styles, structure, graphics, images, and use of interactive features. Content and design work together to enhance the message of the site through visuals and text.</p>

                          <img className="w-100 mb-3" src={require('../images/blogs/blog3-3.jpg').default} alt="" />
                          <p className="mb-3">In order to be irreplaceable, one must always be different.And to make that difference, Holo provides you with the best service.Holo works to add that spark between you and your customers.</p>

                          <img className="w-100 mb-3" src={require('../images/blogs/blog3-3.jpg').default} alt="" />
                          <p className="mb-3">Only good packaging is not the best way to publicize your product. A proper planning, strategy, online marketing, thinking out of the box are the need of the hour. The first place a customer searches for a product or service is online. If your business is online, having a user-friendly, convenient website or mobile app, it will definitely make a good impression and attract and retain customers.</p>

                          <img className="w-100 mb-3" src={require('../images/blogs/blog3-4.jpg').default} alt="" />
                          <p className="mb-3">A mobile phone has become like a compact laptop in your pocket which gives you easy access to using internet services other than calling or reading text messages.The number of people accessing the internet via mobile phone has been increasing and so a mobile app allows you to have more and more customers at your fingertips.</p>

                          <img className="w-100 mb-3" src={require('../images/blogs/blog3-5.png').default} alt="" />
                          <p>The benefits of website designing or having a mobile app are not only for you but for the customer as well. The app should be easily accessible- all information available at one touch, user-friendly, easily downloadable which will be easy and convenient for the customer to use.</p>
                          <p className="mb-3">Mobile app is also one way to bring customers to your website and engage your customers in a new way.</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12">
                <div>
                  <iframe className="w-100" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fholoonline%2F&amp;tabs=timeline&amp;width=360&amp;height=200&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" height="300" scrolling="no" frameborder="0" allowtransparency="true"></iframe>
                </div>
                <h5>Recent Post</h5>
                <ul>
                  <li>
                    <a href="#">Holo - SEO</a>
                  </li>
                  <li>
                    <a href="#">AI- Artificial Intelligence </a>
                  </li>
                  <li>
                    <a href="#">Good design doesn’t cost</a>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Blogdetail3;
