import React from 'react';
import { NavLink } from 'react-router-dom';

class Blog extends React.Component {
  render(){
    return (
      <main>
        <div className="container blogs pt-3">
          <div className="row">
            <div className="col-lg-9 col-md-9 col-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="card mb-4">
                      <NavLink exact to="/holoseo">
                        <img src={require('../images/blogs/blog1.jpg').default} className="card-img-top" alt="blog"/>
                        <div className="card-body">
                          <h5 className="card-title">Holo - SEO</h5>
                        </div>
                      </NavLink>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="card mb-4">
                      <NavLink exact to="/blogdetail2">
                        <img src={require('../images/blogs/blog2.jpg').default} className="card-img-top" alt="blog"/>
                        <div className="card-body">
                          <h5 className="card-title">AI- Artificial Intelligence - Future of IT</h5>
                        </div>
                      </NavLink>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="card mb-4">
                      <NavLink exact to="/blogdetail3">
                        <img src={require('../images/blogs/blog3.png').default} className="card-img-top" alt="blog"/>
                        <div className="card-body">
                          <h5 className="card-title">Good design does not cost But it pays off</h5>
                        </div>
                      </NavLink>
                      </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12">
                <div>
                  <iframe className="w-100" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fholoonline%2F&amp;tabs=timeline&amp;width=360&amp;height=200&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" height="300" scrolling="no" frameBorder="0" allowtransparency="true"></iframe>
                </div>
                <h5>Recent Post</h5>
                <ul>
                  <li>
                    <a href="#">Holo - SEO</a>
                  </li>
                  <li>
                    <a href="#">AI- Artificial Intelligence </a>
                  </li>
                  <li>
                    <a href="#">Good design doesn’t cost</a>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Blog;
