import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

class Dataentry extends React.Component {
  render(){
    return (
      <main>
        <section className="breadcrumb_bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul className="va_breadcrumb">
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/virtualassistant">Virtual Assistant</NavLink>
                  </li>
                  <li>Data Entry & Market Research</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <section>
            <div className="row">
              <div className="col-md-9 col-12">
                <div className="service_name">
                  <h1>Data Entry & Market Research</h1>
                  <h2>Best Virtual service provider </h2>
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="call_btn mt-3">
                  <NavLink to="/contact">Request Call Back</NavLink>
                </div>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-md-12">
                <div class="child_services">
                  <p>Included Services</p>
                </div>
                <div class="box pl-10">
                  <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Online Data Entry</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Collecting and entering data in databases, maintaining
                            accurate records of valuable company information
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>eBook Data Entry</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Data entry, scanning, optical character recognition,
                            formatting, proofreading,{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="box-part text-center">
                        <div class="title">
                          <h4>Microsoft Excel</h4>
                        </div>
                        <hr class="service_d_hr" />
                        <div class="text">
                          <span>
                            Maintaining database by entering new and updated
                            customer and account information.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    );
  }
};

export default Dataentry;
